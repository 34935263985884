export { AUTH_API, SUPPORT_API } from './env';

// Auth API
export const AUTH_LOGIN_PATH = '/v1/auth/admin-console';

// Support API
const SUPPORT_API_PATH = '/v1';

const SUPPORT_DEVICE_PATH = `${SUPPORT_API_PATH}/device`;
export const SUPPORT_ACCOUNT_DETAILS_PATH = `${SUPPORT_API_PATH}/account-details`;
export const SUPPORT_DEVICE_INSTALLATION_HISTORY_PATH = `${SUPPORT_DEVICE_PATH}/installation/history`;
export const SUPPORT_DEVICE_DIAGNOSTICS_PATH = `${SUPPORT_DEVICE_PATH}/diagnostics`;
export const SUPPORT_DEVICE_DIAGNOSTICS_HISTORY_PATH = `${SUPPORT_API_PATH}/device/diagnostics/tag-history`;
